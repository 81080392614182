<template>
  <div>
    <!-- input search -->
    <div class="d-flex ">
      <b-col
        md="3"
      >
        <b-form-group
          label="Année universitaire"
        >
          <v-select
            v-model="academicYear"
            :clearable="false"
            label="academic_year"
            placeholder="Année universitaire"
            :options="academicYears"
            disabled
          />

        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group
          label="Semester"
        >
          <v-select
            v-model="semester"
            :clearable="false"
            placeholder="semester"
            :options="semesters"
            @input="getSubjects"
          />

        </b-form-group>
      </b-col>

      <!-- <b-col md="3">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </b-col> -->
    </div>
    <div
      v-if="load === 'true'"
      class="text-center mb-2"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <!-- table -->
    <h4
      style="color:#0020d1;"
      class="mt-2"
    >
      Semestere 1 :
    </h4>
    <vue-good-table
      :columns="columns"
      :rows="semester1"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <div class="demo-inline-spacing">
              <b-button
                v-if="props.row.groupe.active_assiduite == true"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :to="{ name: 'add-notes-asiduite',params: { id: props.row.groupe.id, subjectIddd: props.row.subject.id} }"
                variant="primary"
              >
                Assiduité
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :to="{ name: 'add-notes-asiduite',params: { id: props.row.groupe.id, subjectIddd: props.row.subject.id} }"
                variant="primary"
                disabled
              >
                Assiduitée
              </b-button>
              <b-button
                v-if="props.row.groupe.active_ds == true"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :to="{ name: 'add-notes-ds',params: { id: props.row.groupe.id, subjectId: props.row.subject.id} }"
                variant="primary"
              >
                DS
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :to="{ name: 'add-notes-ds',params: { id: props.row.groupe.id, subjectId: props.row.subject.id} }"
                variant="primary"
                disabled
              >
                DS
              </b-button>

              <b-button
                v-if="props.row.groupe.active_exam == true"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :to="{ name: 'add-notes-exam',params: { id: props.row.groupe.id, subjectIdd: props.row.subject.id} }"
                variant="primary"
              >
                Examen
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :to="{ name: 'add-notes-exam',params: { id: props.row.groupe.id, subjectIdd: props.row.subject.id} }"
                variant="primary"
                disabled
              >
                Examen
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :to="{ name: 'show-notes',params: { id: props.row.groupe.id, subjectid: props.row.subject.id} }"
                variant="warning"
              >
                Détail Note
              </b-button>
            </div>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap "> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <h4
      style="color:#0020d1;"
      class="mt-2"
    >
      Semestere 2 :
    </h4>
    <vue-good-table
      :columns="columns"
      :rows="semester2"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Name -->
        <div
          v-if="props.column.field === 'username'"
          class="text-nowrap"
        >
          <b-avatar
            src="/assets/images/avatars/user.png"
            class="mx-1"
          />
          <span class="text-nowrap">{{ props.row.username }}</span>
        </div>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <router-link
              class="text-warning"
              :to="{ name: 'teaching-units-edit',
                     params: { id: props.row.id} }"
            >    <feather-icon
              :id="`invoice-row-${props.row.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
            /></router-link>

            <b-tooltip
              title="Modifier"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-edit-icon`"
            />

            <feather-icon
              :id="`invoice-row-${props.row.id}-trash-icon`"
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              size="16"
              @click="deleteTeachingUnit(props.row.id)"
            />
            <b-tooltip
              title="Supprimer"
              class="cursor-pointer"
              :target="`invoice-row-${props.row.id}-trash-icon`"
            />

          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->

    </vue-good-table>
  </div>
</template>

<script>
import {
  BAvatar, BPagination, BFormGroup, BFormSelect, BButton,
  BSpinner, BTooltip, BCol,

} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeAuth from '@/store/store'
import vSelect from 'vue-select'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    // BFormInput,
    BFormSelect,
    BButton,
    BSpinner,
    BTooltip,
    vSelect,
    BCol,
  },
  data() {
    return {
      load: 'false',
      pageLength: 10,
      id: '',
      user: {},
      academicYear: {},
      academicYears: [],
      semester: '',
      level: '',
      semesters: ['semester 1', 'semester 2'],
      levels: [1, 2, 3, 4, 5, 6, 7, 8],
      columns: [
        {
          label: 'Groupe',
          field: 'groupe.group_code',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Unité denseignement',
          field: 'subject.unity',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher',
          },
        },
        {
          label: 'Matière',
          field: 'subject.label',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher date',
          },
        },
        {
          label: 'Volume horaire',
          field: 'subject.hourly_volume',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher date',
          },
        },
        {
          label: 'Coef',
          field: 'subject.coefficient',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher date',
          },
        },
        {
          label: 'Crédit',
          field: 'subject.credit',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher date',
          },
        },
        {
          label: 'Mode DS',
          field: 'subject.supervised_ds_mode',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher date',
          },
        },
        {
          label: 'Mode Examen',
          field: 'subject.supervised_exam_mode',
          filterOptions: {
            enabled: true,
            placeholder: 'chercher date',
          },
        },
      ],
      semester1: [],
      semester2: [],
      parcours: [],
      parcour: {},
      searchTerm: '',
      currentAcademicYear: {},
    }
  },
  computed: {
    active() {
      const statusColor = {
        true: 'light-success',
        false: 'light-danger',
      }

      return status => statusColor[status]
    },

  },
  created() {
    this.getParcours()
    this.getAcademicYears()
    this.getSubjects()
    this.user = storeAuth.state.user.id
    this.academicYear = storeAuth.state.currentAcademicYear
  },
  methods: {
    async getAcademicYears() {
      const response = await axios.get('api/academic-years/')
      this.academicYears = response.data
    },
    async getParcours() {
      const response = await axios.get('/api/parcours/')
      this.parcours = response.data
    },
    async getSubjects() {
      this.load = 'true'
      const response = await axios.get('/api/subjects/subject_distribution/', {
        params: {
          academic_year_id: this.currentAcademicYear.id, teacher_id: storeAuth.state.user.id, semester: this.semester,
        },
      })
      const result = response.data
      this.semester1 = result.filter(x => x.semester === 'semester 1')
      this.semester2 = result.filter(x => x.semester === 'semester 2')
      // this.semaines = response.data
      this.load = 'false'
    },

    formatFn(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      const day1 = day.substr(0, 2)
      const h = date.slice(11, 19)
      return `${year}-${month.padStart(2, '0')}-${day1.padStart(
        2,
        '0',
      )} | ${h}`
    },
    deleteTeachingUnit(id) {
      this.$swal({
        title: 'Vous êtes sûr?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'oui je veux supprimer ! ',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.load = 'true'
        if (result.value) {
          axios
            .delete(`/api/teaching-units/delete/${id}/`)
            .then(() => {
              this.load = 'false'
              this.getTeachingUnits()
              setTimeout(() => {
                this.showToast('success', 'top-center', 'unités enseignement supprimée avec succés')
              }, 1000)
            })
            .catch(error => {
              this.load = 'false'
              setTimeout(() => {
                this.showToast('danger', 'top-center', error.toString())
              }, 1000)
            })
        } else if (result.dismiss === 'cancel') {
          this.load = 'false'
          this.$swal({
            title: 'Fermer',
            text: 'unités enseignement Non Supprimée :)',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    // toast
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Notification',
            icon: 'InfoIcon',
            text,
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>
<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
